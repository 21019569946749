<template>
  <!--
    A grid (coordinate system) with the center being the bottom left corner of the SVG.
  -->
  <g class="zoom-point">
    <template>
      <svg
        v-for="(zoomPoint, index) in zoomPoints"
        ref="zoomPointWrapper"
        :key="`zoomPoint-${ index }`"
        :index="index"
        :x="zoomPoint.pan"
        :y="-zoomPoint.tilt"
        :class="`zoomPointWrapper ${ edit ? 'cursor-move' : ''}`"
      >
        <circle
          :key="`zoomPoint-circle-${ index }`"
          :r="7 * zoomFactor"
          :fill="color"
        />
        <circle
          v-if="edit"
          :key="`zoomPoint-rect-${ index }`"
          :cx="-4.5 * zoomFactor"
          :cy="4.5 * zoomFactor"
          :r="3 * zoomFactor"
          :fill="color"
        />
        <text
          v-if="edit"
          :key="`zoomPoint-circleTextIndex-${ index }`"
          :x="-6 * zoomFactor"
          :y="6 * zoomFactor"
          :style="`font-size: ${ 0.333 * zoomFactor }rem`"
          class="zoomPointIndex"
        >{{ index }}</text>
        <text
          :key="`zoomPoint-circleTextZoom-${ index }`"
          :x="0"
          :y="1 * zoomFactor"
          :style="`font-size: ${ 0.5 * zoomFactor }rem`"
          dominant-baseline="middle"
          text-anchor="middle"
          class="zoomPointZoom"
        >{{ zoomPoint.zoom }}</text>
      </svg>
    </template>
  </g>
</template>

<script>
import interact from 'interactjs';

export default {
  name: 'ZoomPoint',
  props: {
    zoomPoints: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default () {
        return "green";
      }
    },
    scale: {
      type: Object,
      required: false,
      default: () => ({
        scaleX: 1,
        scaleY: 1,
      })
    },
    zoomFactor: {
      type: Number,
      required: false,
      default () {
        return 1;
      }
    }
  },
  mounted: function () {
    this.addDraggable();
  },
  beforeDestroy: function () {
    this.removeDraggable();
  },
  methods: {
    update () {
      this.$nextTick(() => {
        this.removeDraggable();
        this.addDraggable();
      });
    },
    round (number) {
      //Round values to 2 decimal digits
      return Math.round(number * 1e2) / 1e2;
    },
    removeDraggable () {
      if (!this.edit) {
        return;
      }

      if (this.$refs.zoomPointWrapper) {
        this.$refs.zoomPointWrapper.forEach(o => {
          interact(o).unset();
        });
      }
    },
    addDraggable () {
      if (!this.edit) {
        return;
      }

      if (!this.$refs.zoomPointWrapper) {
        return;
      }

      this.$refs.zoomPointWrapper.forEach(o => {
        interact(o)
        .draggable({ max: Infinity, restrict: { elementRect: { top: 0, left: 0, bottom: 1, right: 1 } },
          onmove: (event) => {
            let index = event.target.attributes.index.value;
            this.zoomPoints[index].pan += event.dx * this.scale.scaleX;
            this.zoomPoints[index].tilt -= event.dy * this.scale.scaleY;

            if(this.round(this.zoomPoints[index].pan) > 170) {
              this.zoomPoints[index].pan = 170;
            }
            else if(this.round(this.zoomPoints[index].pan) < -170) {
              this.zoomPoints[index].pan = -170;
            }
            else {
              this.zoomPoints[index].pan = this.round(this.zoomPoints[index].pan);
            }

            if(this.round(this.zoomPoints[index].tilt) > 80) {
              this.zoomPoints[index].tilt = 80;
            }
            else if(this.round(this.zoomPoints[index].tilt) < -80) {
              this.zoomPoints[index].tilt = -80;
            }
            else {
              this.zoomPoints[index].tilt = this.round(this.zoomPoints[index].tilt);
            }
            this.$emit('changePtz', this.zoomPoints[index]);
          }
        });
      });
    },
  }
}
</script>

<style scoped>
svg {
  overflow: visible;
}
g {
  opacity: 0.7;
}
.zoomPointIndex {
  fill: white;
  position: relative;
}
.zoomPointZoom {
  fill: white;
  position: relative;
}
</style>
